<template>
  <v-container fluid>
    <base-page-title>Profile</base-page-title>
    <BaseNavigationButton
      tooltipText="Back to Quotes Index"
      nextRoute="quotes"
      navIcon="mdi-chevron-left"
      color="warning"
      route
      :next="false"
    />
    <v-card class="mt-16" outlined>
      <v-card-title class="justify-center">
        <v-avatar color="primary" size="100" class="mt-n16">
          <span class="white--text display-3 font-weight-bold">{{
            avatarInitial
          }}</span>
        </v-avatar>
      </v-card-title>
      <v-card-text class="px-10">
        <v-toolbar dense flat dark rounded color="accent">
          <v-toolbar-title>User's Profile</v-toolbar-title>
        </v-toolbar>
        <v-row dense class="my-2">
          <v-col cols="12" md="6">
            <span class="pl-4 body-2 font-weight-bold">Name</span>
            <v-alert dense class="my-1 body-1 font-weight-light" data-cy="user-profile-user-name-ui">{{
              projectUser.username
            }}</v-alert>
          </v-col>
          <v-col cols="12" md="6">
            <span class="pl-4 body-2 font-weight-bold">Email Address</span>
            <v-alert dense class="my-1 body-1 font-weight-light">{{
              projectUser.email
            }}</v-alert>
          </v-col>
        </v-row>
        <v-row v-if="projectUser.company_request.length > 0" dense>
          <v-col cols="12">
            <span class="pl-4 body-2 font-weight-bold"
              >Request to join Company</span
            >
            <v-alert dense class="my-1 body-1 font-weight-light">{{
              projectUser.company_request
            }}</v-alert>
          </v-col>
        </v-row>
        <edit-profile-form
          v-else
          @saveData="saveForm"
          :projectUser="projectUser"
          :companyRequest="true"
        />
        <v-row dense class="mt-2">
          <v-col cols="12">
            <span class="pl-4 body-2 font-weight-bold">Billing Address</span>
            <v-textarea
              name="billing_address"
              type="text"
              rows="4"
              readonly
              auto-grow
              flat
              solo
              v-model="projectUser.billing_address_request"
              class="ml-1 subtitle-1"
            />
          </v-col>
        </v-row>
        <v-checkbox
          v-model="projectUser.shipping_same_as_billing_request"
          label="Shipping Address same as Billing Address"
          color="secondary"
          dense
          disabled
          hide-details
          class="ma-3 pb-5 caption"
        ></v-checkbox>
        <v-row v-if="!projectUser.shipping_same_as_billing_request">
          <v-col cols="12">
            <span class="pl-4 body-2 font-weight-bold">Shipping Address</span>
            <v-textarea
              name="shipping_address"
              type="text"
              rows="4"
              readonly
              auto-grow
              flat
              solo
              v-model="projectUser.shipping_address_request"
              class="ml-1 subtitle-1"
            />
          </v-col>
        </v-row>
        <v-checkbox
          v-model="projectUser.nda_request"
          label="Request NDA"
          color="secondary"
          dense
          disabled
          hide-details
          class="ma-3 pb-5 caption"
        ></v-checkbox>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-row class="mx-5 my-3">
          <v-col cols="12" :md="isAdmin ? `4` : `6`">
            <EditProfileForm @saveData="saveForm" :projectUser="projectUser"/>
          </v-col>
          <v-col cols="12" :md="isAdmin ? `4` : `6`">
            <ChangePasswordForm @savePassword="changePassword" />
          </v-col>
          <v-col v-if="isAdmin" cols="12" md="4">
            <CreateRelationModal
              toolbarText="Company"
              labelText="Company Name"
              :arrayList="results"
              @searchCreateRelation="onSearchCreateRelation"
              @onCreateRelation="createRelation"
              @onClearList="clearList"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-card v-if="company" class="mt-2" outlined>
      <v-card-text class="px-10">
        <v-toolbar dense flat dark rounded color="accent">
          <v-toolbar-title>Company Profile</v-toolbar-title>
        </v-toolbar>
        <v-row dense class="my-2">
          <v-col cols="12">
            <span class="pl-4 body-2 font-weight-bold">Name</span>
            <v-alert dense class="my-1 body-1 font-weight-light">{{
              company.name
            }}</v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="pl-4 mb-2 body-2 font-weight-bold">Users:</div>
            <v-chip
              v-for="(user, index) in companyUsersList"
              :key="index"
              color="secondary"
              text-color="white"
              class="ml-3 body-1"
              >{{ user.name }}</v-chip
            >
          </v-col>
        </v-row>
        <v-btn
          v-if="company.nda"
          class="mt-5"
          color="secondary"
          @click="onDownloadNDA(company.nda)"
          >Download NDA on file</v-btn
        >
      </v-card-text>
      <v-card-actions v-if="isAdmin" class="justify-center">
        <v-row class="mx-5 my-3">
          <v-col cols="12" md="6">
            <CompanyFormModal
              @saveData="saveCompanyProfile"
              :company="company"
              :editMode="true"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snack" :timeout="2000" :color="snackColor" centered>
      <v-container class="body-1 py-0">
        <v-row dense>
          <v-col class="d-flex justify-center">
            <div>
              {{ snackText }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-snackbar>
  </v-container>
</template>
<script>
import { fileDownloader } from "@/helpers/basehelper.js";
export default {
  name: "ProfilePage",
  data() {
    return {
      results: null,
      snack: false,
      snackColor: "primary",
      snackText: "Company profile has been saved.",
    };
  },
  components: {
    EditProfileForm: () => import("../components/EditProfileForm.vue"),
    ChangePasswordForm: () => import("../components/ChangePasswordForm.vue"),
    CreateRelationModal: () => import("../components/CreateRelationModal.vue"),
    CompanyFormModal: () =>
      import("@/modules/company-profile/components/CompanyFormModal.vue"),
  },
  computed: {
    isAdmin() {
      return this.$store.getters["auth/isAdmin"];
    },
    avatarInitial() {
      return this.projectUser.username.charAt(0).toUpperCase();
    },
    company() {
      return this.$store.getters["projects/company"];
    },
    projectUser() {
      return this.$store.getters["projects/projectUser"];
    },
    companyUserCount() {
      return Object.keys(this.company.users).length;
    },
    companyUsersList() {
      return this.companyUserCount > 0
        ? Object.entries(this.company.users).map(([k, v]) => ({
            pk: k,
            name: v,
          }))
        : null;
    },
  },
  methods: {
    fileDownloader,
    async saveForm(payload) {
      try {
        await this.$store.dispatch("projects/updateUserById", payload);
        this.snackText = "User profile has been saved.",
        this.snack = true;
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async changePassword(payload) {
      try {
        const response = await this.$store.dispatch(
          "auth/changePassword",
          payload
        );
        this.$store.commit("ui/SNACK_BAR", response);
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveCompanyProfile(payload) {
      try {
        await this.$store.dispatch("projects/updateCompanyById", payload);
        this.snackText = "Company profile has been saved.",
        this.snack = true;
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    onDownloadNDA(payload) {
      const actionPayload = {
        url: payload,
        file: payload.split("/").pop(),
      };
      this.fileDownloader(actionPayload);
    },
    async onSearchCreateRelation(payload) {
      console.log("Search is clicked with string: ", payload);
      try {
        this.results = await this.$store.dispatch("projects/searchCompanies", {
          params: { search: payload }
        });
        console.log(this.results);
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async createRelation({ id }) {
      console.log("Item is selected with id: ", id);
      if (
        this.projectUser.groups.map((element) => element.id).includes(id)
      ) {
        this.$store.commit(
          "ui/SNACK_BAR",
          "User already part of the Company."
        );
        this.clearList();
        return;
      }
      try {
        await this.$store.dispatch("projects/patchCompanyToUser", {
          id: this.projectUser.pk,
          value: id,
          data: {
            groups: [id],
          },
        });
        this.clearList();
        this.snackText = "User added to Company.",
        this.snack = true;
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    clearList() {
      this.results = null;
    },
  },
};
</script>
